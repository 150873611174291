import React from "react";
import StoryPage from "../../components/story-page";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/eric-lloyd-jones.jpeg";
  const fullName = "Eric Lloyd Jones";
  const zoomUrl = "https://us02web.zoom.us/j/86429479315";
  const zoomPhoneNumber = "(647) 374-4685";
  const zoomMeetingId = "864 2947 9315";
  const birthDate = "February 2, 1943";
  const deathDate = "October 26, 2020";
  const memorialDate = "Saturday November 7th, 2020";
  const memorialTime = "2pm ET";
  const inviteText =
    "Please join us as we commemorate the life and legacy of Eric Lloyd Jones. Loved and respected by so many, Eric left his impressionable mark in so many ways. Although we can't gather in person now as we may like, this virtual memorial will allow us to reach family, friends, colleagues and acquaintances both near and far safely, just the way he would have preferred.";
  const obituary = `Eric Lloyd Jones, born February 2, 1943 in Cold Spring, Hanover, Jamaica sadly passed away on October 26, 2020 at St. Joseph's Health Centre in Toronto, Ontario. A loving and happy father, husband, grandfather, brother, uncle and friend. Eric was the second of seven children born to Hubert and Lily Jones and immigrated to Ontario Canada in 1967. Known for his worry-free, lighthearted and  consistently joyous disposition, Eric was affectionately known by his siblings, family and friends as Sam.
  \nHe was also well known for his remarkable selfless nature, which was always evident and on display to not only family and friends but even strangers alike. A self-taught handyman in his everyday life and a few of his workplaces, Eric also spent part of his working life as a civil servant for the federal government. One thing Eric very consistently did was live life to the fullest and always with laughter. His passions were vast,  ranging from a love for good culinary experiences, enjoying live theater,  travelling to various countries to learn about and partake in their local culture and way of life, to just mere storytelling while gathering his family and friends, he certainly knew how to command and captivate an audience.
  \nEric is survived  by his life partner of over 30 years, Barbara and his four children: Winsome, Deric, Tara and Andrew.  Eric will be sorely missed by many, but the influence and impact he has left lives on.`;
  const storiesRecordUrl = "https://stories.afterword.com/fok0zbac4";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rxp2xkc34vbxffz2fsc0fddjbm5i0gfnvya15prq";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    />
  );
};

export default ThisPage;
